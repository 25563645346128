import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Depth First Search | Code Tutorial'}
			subHeader={''}
			description={'Depth-first search (DFS) is an algorithm for traversing or searching tree or graph data structures. The algorithm starts at the root node (selecting some arbitrary node as the root node in the case of a graph) and explores as far as possible along each branch before backtracking.'}
			keywords={[]}
			embeddedVideo={'k9cUUsB8_GE'}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/fbd5dc220183dcd3ff34f1ad3a790187/raw/676ed235eac6edae66a80ecef0f144a581aff1b1/depth_first_search.py'}		

			></CodeSnippet>
		);
	}
}

export default View;
